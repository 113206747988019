import { Badge } from 'antd';
import React, { useState } from 'react'
import useWebSocket from 'react-use-websocket';

function WsBadgeState() {
    const {readyState} = useWebSocket(process.env.REACT_APP_WS + "?ticker=BAN", {
        share: true,
    })
  
    if (readyState !== 1) {
        return <Badge dot status="warning"  text="Connecting..." />
    }
    else {
        return <Badge status="processing"  text="Real-time data" />
    }
}

export default WsBadgeState