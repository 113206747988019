import { Card, Collapse, Skeleton } from 'antd'
import React, { useEffect } from 'react'
import VsBTC from './main/VsBTC';
import useSWR from 'swr'
const { Panel } = Collapse;

function FAQ() {
  const { data, error, isLoading } = useSWR(`/max`)
  const [activeKey, setActiveKey] = React.useState(["banano-faucet"])
  useEffect(() => {
    // set default active key with url hash
    if (window.location.hash) {
      // set all  active keys with url hash
      setActiveKey([...window.location.hash.split("#")[1].split(','), "banano-faucet"])
      console.log([...window.location.hash.split(','), "banano-faucet"])
    }
  } , [])
  const name = "banano"
  return (
    <div style={{marginTop: 16}}>
    <Collapse defaultActiveKey={["5"]} activeKey={activeKey} ghost onChange={
      (e) => {
        setActiveKey(e)
        window.history.pushState({}, "", `#${e}`)
      } 
    }>
    <Panel forceRender header="How to get Banano ?" key="banano-faucet">
        <p>
      You can try <a target={"_blank"} href="https://nanswap.com/banano-faucet"><b>Banano Faucet</b></a> to get some {name} for free in seconds.<br/>
        </p>
      </Panel>
      <Panel header="Banano vs Dogecoin" key="ban-vs-doge" forceRender>
          <VsBTC />
      </Panel>



      <Panel forceRender header="What is Confirmation Time ?" key="what-is-conf-time">
        <p>
          Confirmation time is the duration it takes for a transaction to be confirmed on the network. <br/>
          This can be used to estimate how long it will take for your transactions to be confirmed on {name}, which is especially useful when the network is congested.<br/>
          <br/>
          Here, this number is calculated by sending a block from nanospeed's {name} node every minutes, and measuring the time it takes for the block to be seen as confirmed by the network. <br/>
          Given that the same account is used every time, nanospeed transactions should be deprioritized by the network, confirmation time indicated here can therefore be considered as the worst possible case.<br/>
        </p>
      </Panel>
      <Panel forceRender header="What is CPS ?" key="what-is-cps">
      <p>
  Confirmation Per Second (CPS) is the number of block confirmed on {name} each second. <br/>
  When CPS is high it means that {name} is hevily used, it becomes more congested and confirmation time of transactions will have good chance of increasing until CPS returns lower.
  </p>
  Max CPS can increase with better network hardware, more efficient {name}-node source code and or less <a href="https://docs.nano.org/glossary/#principal-representative">PR Nodes</a>.
  <br/>
  CPS is calculated each second with nanospeed's {name} node websocket of confirmed blocks.<br/>
  Max CPS recorded by nanospeed: <b>{isLoading || error ? <Skeleton.Input active size='small' /> : data.cps.value} CPS</b>.
      </Panel>
      <Panel forceRender header="What is BPS ?" key="what-is-bps">
        <p>
          Block Per Second (BPS) is the number of block sent on the network each second. 
          This number is calculated each second with nanospeed's {name} node websocket of unconfirmed blocks.         </p>
          Max BPS recorded by nanospeed: <b>{isLoading || error ? <Skeleton.Input active size='small' /> : data.bps.value} BPS</b>.
      </Panel>
    
    </Collapse>

    </div>
  )
}

export default FAQ