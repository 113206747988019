import React from 'react'
import { msFormatter } from '../../utils'
import prettyMilliseconds from 'pretty-ms'
import { Button, Divider, Skeleton, Space } from 'antd'
import useSWR from 'swr'
import { ShareAltOutlined } from '@ant-design/icons'
function VsBTC() {
  const { data, error, isLoading } = useSWR(`/conf-times-box-global?period=1M&ticker=BAN`)
  const { data: dataBtc, error: errorBtc, isLoading: isLoadingBtc } = useSWR(`/btc-stats`)
  

    const energyBtc1tx = (dataBtc?.electricity[0]?.['Output_kWh'] * 1000) / 10  //https://digiconomist.net/api-documentation/ todo: use doge instead of /10 for approx

    const energyNano1tx = 0.0471 //https://www.reddit.com/r/nanocurrency/comments/10xqv9h/comment/j7tr7wv/?utm_source=share&utm_medium=web2x&context=3

    const avgBtc = (40 * 60 * 1000 ) // kraken.com
    const name = "Banano"
    if (error) return <div>failed to load</div>
    if (isLoading || isLoadingBtc) return <Skeleton active />
  return (
    <div style={{textAlign: "left", marginTop: 8, marginBottom: 16}}>
    {/* With {msFormatter(data.q99)}, <span style={{color: "#389e0d"}}>0 fees</span> and <span style={{color: "#389e0d"}}>{
      energyNano1tx
    }Wh</span> to confirm one transaction,  */}
    Banano is: 
    <Space>

    <ul>
        <li>{(+(avgBtc / data.q99).toPrecision(3)).toLocaleString()}x faster than Dogecoin <small>[1]</small></li>
        {/* <li>{+((14*60*1000) / data.q99).toPrecision(3)}x faster than Ethereum</li> */}
        <li>{(+(energyBtc1tx / energyNano1tx).toPrecision(5)).toLocaleString()}x more energy efficient than Dogecoin <small>[2]</small></li>
        <li>Infinitely cheaper than Dogecoin <small>[3]</small>
        </li>
    </ul>
    <Button size='middle' type='dashed' style={{ marginRight: 8, marginBottom: 8, marginTop: 8 }} icon={<ShareAltOutlined />}
 onClick={() => navigator.share({
title: "BananoSpeed", 
text: `$BAN @bananocoin is ${(+(avgBtc / data.q99).toPrecision(3)).toLocaleString()}x faster than $DOGE, ${(+(energyBtc1tx / energyNano1tx).toPrecision(5)).toLocaleString()}x more energy efficient and infinitely cheaper. #nanospeed`, url: "https://ba.nanospeed.info/faq#ban-vs-doge"})} >Share</Button>    
   </Space>
    <p>
    Moreover, Banano's <a href="https://docs.nano.org/glossary/#open-representative-voting-orv" target="_blank"> ORV</a> consensus does not suffer from <a target='_blank' href="https://medium.com/@clemahieu/emergent-centralization-due-to-economies-of-scale-83cc85a7cbef">emergent centralization due to economies of scale</a>
    , unlike Dogecoin's PoW. 
        
 </p>
    <Divider />
    <small>
        <u> Sources as of {new Date().toLocaleDateString()}:</u><br />
        [1] 
        {name}: {prettyMilliseconds(data.q99)} (<a href="https://nanospeed.info" target="_blank">nanospeed.info</a>/ 99th Percentile / 1 month)
        Doge: {prettyMilliseconds(avgBtc, {compact: false})} (<a href="https://support.kraken.com/hc/en-us/articles/203325283-Cryptocurrency-deposit-processing-times" target="_blank">kraken.com</a>). 
        <br />
        [2]
        Banano: {energyNano1tx/1000} kWh (<a href="https://www.reddit.com/r/nanocurrency/comments/10xqv9h/comment/j7tr7wv" target="_blank">reddit.com/r/nanocurrency/comments/10xqv9h/comment/j7tr7wv</a>)
        Doge: {energyBtc1tx/1000} kWh (<a href="https://digiconomist.net/api-documentation/" target="_blank">digiconomist.net API</a>).
        <br />
        [3]
        Banano: 0 fees (<a href="https://docs.nano.org/whitepaper/english/#introduction" target="_blank">docs.nano.org/whitepaper</a>)
        Doge: $0.025 (<a href="https://bitinfocharts.com/comparison/dogecoin-transactionfees.html" target="_blank">bitinfocharts.com</a>)
    </small>
    </div>
  )
}

export default VsBTC