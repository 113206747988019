import React, { useState, useEffect } from 'react';
import { Box } from '@ant-design/plots';
import { Segmented, Skeleton, Space } from 'antd';
import prettyMilliseconds from 'pretty-ms';
import useSWR from 'swr'
import { AVAILABLE_PERIODS } from '../utils';

function BoxPlot({period, scale, setScale}) {
  const { data, error, isLoading } = useSWR(`/conf-times-box?period=${AVAILABLE_PERIODS[period].value}&ticker=BAN`, )

 
  const [maxQ, setP] = useState("q90")
  console.log(error)
  if (error) return <div>Failed to load. Please try again in few seconds.</div>
  let diff
  if (isLoading){
    diff = 0
  }
  else{
    diff = new Date(data[data.length-1].x) - new Date(data[0].x)
  }
  console.log({diff});
  let format

  if (diff > 1000*60*60*24){
    format = "day"
  }
  else {
    format = "time"
  }
  const config = {
    data: isLoading ? [] : data,
    height: 300,
    xField: 'x',
    yField: ['min', 'q1', 'median', 'q3',  maxQ],
    tooltip: {
      fields: ['min', 'q1', 'median', 'q3',  'q90', 'q99', 'q999', 'max'],
    },
    yAxis: {
      label: {
        formatter: (v) => {
          v = parseFloat(v)
          if (!isFinite(v) || v == 0){
            return v;
          } 
          else{
            return prettyMilliseconds(v)
          }
        },
      },
      // min: 170,
      base: 10,
      type: scale
      // max: 1000 * 60 *5,
    },
    boxStyle: {
      stroke: '#545454',
      fill: '#1890FF',
      fillOpacity: 0.3,
    },
    animation: false,
    meta: {
      
      min: {
        formatter: (v) => "" + prettyMilliseconds(v),
        alias: 'Minimum'
      },
      max: {
        formatter: (v) => "" + prettyMilliseconds(v),
        alias: 'Maximum'
      },
      q1: {
        formatter: (v) => "<" + prettyMilliseconds(v),
        alias: 'p25',
      },
      median: {
        formatter: (v) => "<" + prettyMilliseconds(v),
      },
      q3: {
        formatter: (v) => "<" + prettyMilliseconds(v),
        alias: 'p75',
      },
      q90: {
        formatter: (v) => "<" + prettyMilliseconds(v),
        alias: 'p90',
      },
      q99: {
        formatter: (v) => "<" + prettyMilliseconds(v),
        alias: 'p99',
      },
      q999: {
        formatter: (v) => "<" + prettyMilliseconds(v),
        alias: 'p999',
      },
      x: {
        alias: 'Date',
        formatter: (date) => {
          if (format === "day"){
            return new Date(date).toLocaleDateString()
          } 
          else if (format === "daytime"){
            return new Date(date).toLocaleString()
          }
          else {
            return new Date(date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
          }
        },
      },
    },

  };


  return (
    <>

 <div style={{maxHeight: 300, marginBottom: 16}}>
      <Box  {...config} loading={isLoading} />
  </div>
      <div style={{marginTop: 8, marginBottom: 16}}>
<Space>
        <Segmented onChange={(value) => {
           setScale(value)
        }} value={scale} options={[{
          label: 'Linear', value: 'linear'
        }, { label: 'Log', value: "log" }]} />

<Segmented onChange={(value) => {
   setP(value)
}} value={maxQ} options={[{
label: 'p90', value: 'q90'}, {label: 'p99', value: "q99"}, {label: 'p99.9', value: "q999"}, {label: 'All', value: "max"}]} />

</Space>
</div>
    </>
   
  );
}

export default BoxPlot;