import logo from './logo.svg';

import { Alert, Badge, Button, Card, Col, DatePicker, Divider, Popover, Row, Segmented, Skeleton, Space, Statistic, Tabs, Tooltip, Typography } from 'antd';
import ConfTimesChart from './components/ConfTimes';
import { useCallback, useEffect, useState } from 'react';
import ConfViolin from './components/ViolinPlot';
import LastTxConf from './components/LastTxConf';
import GaugeCPS from './components/GaugeCPS';
import FAQ from './components/FAQ';
import { Liquid } from '@ant-design/charts';
import BoxPlot from './components/BoxPlot';
import { AVAILABLE_PERIODS, fetcherWS, getMsColor, msFormatter, useWindowDimensions } from './utils';
import prettyMilliseconds from 'pretty-ms';
import Appx from './components/MainTabs';
import Percentiles from './components/main/Percentiles';
import VsBTC from './components/main/VsBTC';
import { PercentageOutlined, BoxPlotOutlined, LineChartOutlined, ShareAltOutlined, TableOutlined, FieldTimeOutlined, DashboardOutlined ,
  QuestionCircleOutlined
} from '@ant-design/icons'
import StatsLive from './components/StatsLive';
import useSWR, { preload } from 'swr'
import TableConfs from './components/main/TableConfs';
import CPSBPSChart from './components/CPSBPSChart';

const { Countdown } = Statistic;

function App() {

  // const [data, setData] = useState(null);
  const [dataBox, setDataBox] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [period, setPeriod] = useState("Last Hour")
  const [scale, setScale] = useState("linear")
  const [chartType, setChartType] = useState("line")
  const [tabs, setTabs] = useState("conftime")
  const [subTabs, setSubTabs] = useState("1")
  const [startDate, setStartDate] = useState(new Date())
  const [timeFirstConfirmation, setTimeFirstConfirmation] = useState(400)
  const [cps, setCPS] = useState(null);
  const [bps, setBPS] = useState(null);
  const [wsState, setWsState] = useState(WebSocket.CONNECTING);
  const [periodLive, setPeriodLive] = useState("Live")
  const [isLive, setIsLive] = useState(true)
  const { height, width } = useWindowDimensions();
  useEffect(() => {
    const pathname = window.location.pathname
    console.log(pathname)
    setTabs(pathname === "/" ? "conftime" : pathname.split("/")[1])
    preload(`/last`, fetcherWS)
  }, [])

  const Periods = () => (<Segmented  size='middle' onChange={(value) => {
    console.log(value)
    setPeriod(value)
  }}
    value={period}
    options={Object.keys(AVAILABLE_PERIODS).filter((p) => p !== "Last 10 Minutes")}
    style={(subTabs === "4" || (tabs === "cpsbps")) ? { display: "none" } : {marginBottom: 16, marginTop: -8}}
  />)
  const subItems = [
    {
      key: '1',
      label: <span>
        <PercentageOutlined />
      </span>,
      children: <div style={{ height: "100%" }}>
        <Row justify={"space-evenly"} gutter={32}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <Percentiles period={period} />
          </Col>
          <Col xs={0} sm={0} md={12} lg={8} xl={8}><Percentiles period={"1D"} /></Col>
          <Col xs={0} sm={0} md={0} lg={8} xl={8}><Percentiles period={"7D"} /></Col>
        </Row>
          <Periods />
        <div />
      </div>
    },
    {
      key: '2',
      label: <span>
        <LineChartOutlined />
      </span>,
      children: <>

        <ConfTimesChart period={period} scale={scale} setScale={setScale} /> <Periods />
      </>
    },
    {
      key: '3',
      label: <span>
        <BoxPlotOutlined />
      </span>,
      children: <><BoxPlot period={period} scale={scale} setScale={setScale} /><Periods />
      </>
    },
    {
      key: '4',
      label: <span>
        <TableOutlined />
      </span>,
      children: <><TableConfs /></>
    }
  ]

  const itemsLineChartOutlined = [
    {
      key: 'conftime',
      label: <span><FieldTimeOutlined />
        Conf. times </span>,
      children: <>
        <Tabs type='card' defaultActiveKey="conftime" onChange={setSubTabs} items={subItems} activeKey={subTabs}/>
      </>
    },
    {
      key: 'cpsbps',
      label: <span><DashboardOutlined />
        CPS & BPS </span>,
      children: <>
        <CPSBPSChart isTabActive={
          tabs === "cpsbps"
        } setIsLive={setIsLive} isLive={isLive} period={period} setPeriod={setPeriod} scale={scale} setScale={setScale} cps={cps} periodLive={periodLive} setPeriodLive={setPeriodLive} />
      </>,
    },
    {
      key: 'faq',
      label: <span><QuestionCircleOutlined />FAQ</span>,
      children: <>
        <FAQ />
      </>,
      forceRender: true,
    }
  ]


  return (
    <div className="App">

      <div style={{ margin: 8 }}>

        <Row>
          <Col xs={24} sm={24} md={{
            span: 9,
            offset: 3
          }} lg={{
            span: 8,
            offset: 4
          }}
          >
            <div style={{display: "flex", cursor: "pointer"}} onClick={() => {
              // sort of return to home
              setTabs('conftime')
              setSubTabs('1') 
              window.history.pushState({}, "", `/`)
            }}>
            <div style={{}} ><img src="https://nanswap.com/logo/BAN.svg" width={80} alt='banano_logo' style={{marginTop: 28, marginRight: 8}}/></div>
            <div style={{marginLeft: 8}}>
              <Typography.Title style={{ color: "black", marginBottom: 8, marginTop: 24 }}>Bananospeed</Typography.Title>
              <Typography.Title level={3} style={{ color: "black", marginTop: 0 }}>Banano Monitoring Tool</Typography.Title>
            </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>

            {/* <Card style={{textAlign: "left"}}> */}
            {/* <LastTxConf data={data}  /> */}
            {/* </Card> */}
            {/* <Row style={{textAlign: "center"}}>
        <Col span={12} >
          <GaugeCPS />
        </Col>
        <Col span={12}>
          <GaugeCPS />
        </Col>
        </Row> */}
            <div style={{ textAlign: "center", }}>

              <Card style={{ maxWidth: 480, marginLeft: "auto" }}>

                <Typography.Title level={3} style={{ textAlign: "left", marginTop: 0 }}>Live
                </Typography.Title>
                <StatsLive cps={cps} bps={bps} timeFirstConfirmation={timeFirstConfirmation} wsState={wsState} />
              </Card>
            </div>
          </Col>
        </Row>

        <Card style={{ marginTop: 16 }} title={
          // width < 992 ? "Historical" : ""
          ""
        }>
          <Row gutter={32} style={{}}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Tabs size='small'  style={{ height: "100%" }} type='line' defaultActiveKey={
"conftimes"
                } items={itemsLineChartOutlined} onChange={(e) => {
                  setTabs(e)
                  // set url 
                  if (e === "conftime") {
                    window.history.pushState({}, "", `/`)
                  }
                  else {
                    window.history.pushState({}, "", `/${e}`) 
                  }
                  }}
                  activeKey={tabs} />

            </Col>
            {/* <Col xs={0} sm={0} md={0} lg={12} xl={24}>
              <Card style={{ height: "100%" }} extra={<Space><FieldTimeOutlined />Confirmation times </Space>} title="Historical">
                <Tabs tabPosition='top' type='line' defaultActiveKey="1" items={subItems} onChange={(e) => setTabs(e)} destroyInactiveTabPane />
              </Card>
            </Col> */}
           
          </Row>
        </Card>
        {/* <iframe src="https://nanswap.com/nano-faucet" style={{width: "100%", height: 500, border: "none", marginTop: 32}}></iframe> */}
        {/* <ConfViolin data={data} /> */}
        <Row>
        {
          tabs === "faq" && 
       <Col span={24} style={{textAlign: "right", marginTop: 16, marginBottom: 16}}>
          <div style={{color: "black", }}>{""}
            <a style={{color: "black", textDecoration: "underline"}} href='https://nanospeed.info/'>Powered by nanospeed.info</a>
          </div>
       </Col>
        }
        </Row>
      </div>
    </div>
  );
}

export default App;
