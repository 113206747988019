import React, { useState, useEffect, memo } from 'react';
import { DualAxes, Line } from '@ant-design/charts';
import { Button, Card, Segmented, Space } from 'antd';
import prettyMilliseconds from 'pretty-ms';
import useSWR from 'swr'
import { AVAILABLE_PERIODS, useWindowDimensions } from '../utils';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import useWebSocket from 'react-use-websocket';
import { isEqual } from "lodash-es";
import CPSBPSLiveChart from './main/CPSBPSLiveChart';
import StatsHistorical from './main/StatsHistorical';

const Chart = ({period, setPeriod, scale}) => {
  const { height, width } = useWindowDimensions();


  const { data, error, isLoading } = useSWR(`/blocks?period=${AVAILABLE_PERIODS[period].value}&ticker=BAN`)
  
  if (error) return <><div>Failed to load. Please try again in few seconds.</div></>
  
  let format
  let diff
  if (isLoading) {
    diff = 0
  }
  else {
    diff = new Date(data[data.length - 1].ts) - new Date(data[0].ts)
    if (diff > 1000 * 60 * 60 * 24 * 7) {
      format = "day"
    } else if (diff > 1000 * 60 * 60 * 24) {
      format = "daytime"
    }
    else {
      format = "time"
    }
  }

  
  let alias
  if (AVAILABLE_PERIODS[period].value === "1H") {
    alias = "(Avg. 1m)"
  } else if (AVAILABLE_PERIODS[period].value === "1D") {
    alias = "(Avg. 10m)"
  } else if (AVAILABLE_PERIODS[period].value === "7D") {
    alias = "(Avg. 1h)"
  } else if (AVAILABLE_PERIODS[period].value === "1M") {
    alias = "(Avg. 4H)"
  } else {
    alias = "(Avg. 1d)"
  }

  let parsed = []
  
  if (!isLoading) {
    parsed = [
      ...data.map((d) => ({ ts: d.ts, value: d.bps, cat: "bps" })), 
      ...data.map((d) => ({ ts: d.ts, value: d.cps, cat: "cps" }))
    ]
  }


  const config = {
    // data: [data, blocks],
    height: width > 768 ? 300 : 200,
    data: (isLoading) ? [] : parsed,
    padding: 'auto',
    xField: 'ts',
    yField: 'value',
    seriesField: 'cat',
    meta: {
      ts: {
        alias: 'Date',
        formatter: (date) => {
          if (format === "day") {
            return new Date(date).toLocaleDateString()
          }
          else if (format === "daytime") {
            return new Date(date).toLocaleString()
          }
          else {
            return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          }
        },
      },
      cat: {
        formatter:(arg)=> {
          if (typeof arg !== 'string') return arg
          return arg.toUpperCase() + " " + alias
       },
      },
    },
    yAxis: {
      type: scale,
    },
    smooth: true,
  };
  return <Line  {...config} loading={isLoading} onReady={(plot) => {
    // plot.on('slider:valuechanged', (value) => {
    //   console.log(value)
    // })
    }
    } />
}

function CPSBPSChart({ isLive, setIsLive, setScale, scale, setPeriod, setPeriodLive, periodLive, period,  isTabActive }) {
  // const [period, setPeriod] = useState("Live")
  const [paused, setPaused] = useState(false)

  // Use the useEffect hook to fetch the /conf-times data when the component is mounted
  const [blocksParsed, setBlocksParsed] = useState([])
  const [lastLiveStats, setLastLiveStats] = useState({})
  const [] = useState(true)
  // useEffect(() => {
    // if (isLoading) return
    // 
    // let bps = blocks.map((block) => ({_id: block.ts, value: block.bps, type: "bps"}))
    // let cps = blocks.map((block) => ({_id: block.ts, value: block.cps, type: "cps"}))
    // setBlocksParsed(bps.concat(cps))
    // console.log(blocksParsed)
  // }, [blocks]);



  const Periods = () => {
    return (
      <Segmented block={false} size='middle' onChange={(value) => {
        if (value === "Live") {
          setPeriod("Last Hour")
          setPeriodLive("Live")
          setIsLive(true)
        }
        else {
          setPeriod(value)
          setPeriodLive(value)
          setIsLive(false)
        }
      }} 
      value={ periodLive}
      options={["Live", ...Object.keys(AVAILABLE_PERIODS)]}
      default={"Live"}
      />
    )
  }

  

  

  return (
    <>
      <div style={{ maxHeight: 300, marginBottom: 16 }}>
      {
        paused || isLive ? 
          <span style={{display: 
          isLive ? "block" : "none"
          }}><CPSBPSLiveChart   scale={scale} setScale={setScale} paused={paused} setPaused={setPaused} isTabActive={isTabActive}/>
          </span>
          : null
      }
      {
        !isLive && <Chart period={period} setPeriod={setPeriod} scale={scale}  />
      }
      </div>
      <div style={{ marginTop: 8, marginBottom: 16 }}>
<Space direction='horizontal'>

        <Segmented  style={{marginBottom: 8}} onChange={(value) => {
          setScale(value)
        }} value={scale} options={[{
          label: 'Linear', value: 'linear'
        }, { label: 'Log', value: "log" }]} />


</Space>
<div style={{marginTop: 8}}>
  <Periods />
</div>
{
  isLive  && <StatsHistorical hideMax={true}  />
}
{
  period === "All" && <StatsHistorical hideCountdown={true}  />
}
      
      </div>
    </>

  );
}

export default CPSBPSChart;