import { Badge, Col, Row, Statistic, Tooltip } from 'antd'
import prettyMilliseconds from 'pretty-ms';
import React, { useCallback, useEffect, useState } from 'react'
import CountUp from 'react-countup';
import { getMsColor } from '../utils';
import useWebSocket from 'react-use-websocket';
import WsBadgeState from './WsBadgeState';




function StatsLive() {
  

  const [timeFirstConfirmation, setTimeFirstConfirmation] = useState(500)
  const [cps, setCPS] = useState(null);
  const [bps, setBPS] = useState(null);
  const formatterCPS = (value) => <CountUp preserveValue={true} end={value} decimals={0} duration={0.5} useEasing={false} />;
  const formatterConf = (value) => <CountUp preserveValue={true} end={value} start={500} formattingFn={memoizedCallback} duration={2} useEasing={true} startOnMount={false} />;
  const memoizedCallback = useCallback((value) => prettyMilliseconds(value), []);
  const {sendMessage, lastMessage, readyState} = useWebSocket(process.env.REACT_APP_WS + "?ticker=BAN", {
    onMessage: function (event) {
      let data = JSON.parse(event.data)
      if (data['lastConfTime'] !== undefined) {
        setTimeFirstConfirmation(data['lastConfTime'])
      }
      if (data['cps'] !== undefined) {
        setCPS(data['cps'])
      }
      if (data['bps'] !== undefined) {
        setBPS(data['bps'])
      }
      if (cps !== null && timeFirstConfirmation !== null){
        document.title = `${cps} CPS | ${prettyMilliseconds(timeFirstConfirmation)}`
      }
    }, 
    share: true,
    shouldReconnect: (closeEvent) => true,

  })

  useEffect(() => {

  }, [])

  return (
    <>

      <Row>
          <Tooltip title="Block Per Second">
        <Col span={6} style={{ textAlign: "left" }}>
            <Statistic formatter={formatterCPS} title="BPS" value={bps} suffix="BPS" precision={2} />
        </Col>
          </Tooltip>
        <Col span={12} style={{ textAlign: "center" }}>
          <Statistic formatter={formatterConf} title="Confirmation Time" value={timeFirstConfirmation} precision={0} valueStyle={{
            color: getMsColor(
              timeFirstConfirmation
            )
          }} />
        </Col>
          <Tooltip title="Confirmation Per Second">
        <Col span={6} style={{ textAlign: "right" }}>
            <Statistic formatter={formatterCPS} title="CPS" value={cps} suffix="CPS" precision={2} />
        </Col>
          </Tooltip>
      </Row>
      <div style={{ marginLeft: 8, float: "right" }}>
        <WsBadgeState  />
      </div>
    </>
  )
}

export default StatsLive